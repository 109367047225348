<template>
  <transition name="fade-in-up">
    <router-view></router-view>
  </transition>
</template>

<script>
import jwtService from '@/services/jwt.service'
export default {
  components: {},
  mounted() {
    if (!jwtService.getToken()) {
      this.$router.push('/login');
    }
  }
};
</script>
